import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Transactional Modal" component="modal" variation="modal" hasReactVersion="modal--default" hasVueVersion="modal--buttons" hasAngularVersion="?path=/story/modal--transactional" codepen="QPNEOv" mdxType="ComponentCode" />
    <ComponentCode name="Passive Modal" component="modal" variation="modal--nofooter" hasReactVersion="modal--default" hasVueVersion="modal--default" hasAngularVersion="?path=/story/modal--passive" codepen="bJpeag" mdxType="ComponentCode" />
    <ComponentCode name="Danger Modal" component="modal" variation="modal--danger" hasReactVersion="modal--default" hasVueVersion="modal--danger" hasAngularVersion="?path=/story/modal--transactional&knob-modalType=danger&knob-modalLabel=optional label&knob-modalTitle=Delete service from application&knob-modalContent=Are you sure you want to remove the Speech to Text service from the node-test app?" codepen="YMqWaK" mdxType="ComponentCode" />
    <ComponentCode name="Input Modal" component="modal" variation="modal--input" hasReactVersion="modal--default" hasVueVersion="modal--with-input" codepen="MRyeVV" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="modal" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      